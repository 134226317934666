import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from 'tradesoft-ng-auth';

const root = ReactDOM.createRoot(document.getElementById('root'));

const authConfig = {
  clientId: '172600',
  clientSecret: '737e410d9e01440c8f62f3b6363e7330',
  tokenEndpoint: 'https://xhcpauth.halkyatirim.com.tr/connect/token',
  introspectEndpoint: 'https://xhcpauth.halkyatirim.com.tr/connect/introspect',
  verifyEndpoint: 'https://xhcpauth.halkyatirim.com.tr/auth/verifyToken',
  scope: 'authlevel2',
  // redirectUri: 'https://ts.tradesoft.com.tr/xhcpmodelportfolio/' // burası yayınlanan mikroappin adresi olmalı
   redirectUri: 'http://localhost:3000'
};


root.render(
  <AuthProvider authConfig={authConfig}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
    </AuthProvider>
  // <React.StrictMode>
  //     <App />
  //   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
