import React, { useState, useEffect, useRef, useContext } from 'react';
import Hamburger from 'hamburger-react'
import RefreshIcon from './Icons/refresh.png'
import aselsanImage from './Icons/aselsan.png';
import rightIcon from './Icons/right_icon.png';
import rightIconPas from './Icons/right_icon_pasive.png';
import pdfIcon from './Icons/pdf_icon.png';
import performance_Left from './Icons/performance_Left.png';
import performance_Right from './Icons/performance_Right.png';
import ReactApexChart from 'react-apexcharts';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthContext } from 'tradesoft-ng-auth';
import { Button, Card, Dropdown, Icons, Switch, Table } from "tradesoft-ng-ui/build";
import ApexChart from './Components/chart';
import './App.css';
import { jwtDecode } from "jwt-decode";
function ArastirmaKapsamindakiler() {
  const [data, setData] = useState([]);
  const [Hisse, setSembol] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error2, setError] = useState(null);
  const [show, setShow] = useState({});
  const [period, setPeriod] = useState(1)
  const [barCount, setBarCount] = useState(34);
  const [btnSembol, setBtnSembol] = useState();
  const [btnClicked, setBtnClicked] = useState(false);
  const [HisseDetail, setSembolDetail] = useState([]);
  const [activeButton, setActiveButton] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [showPerformance, setShowPerformance] = useState(false);
  const menuRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [firstresult, setFirstResult] = useState([]);
  const [graf, setItemsGrafik] = useState([]);
  const [graf2, setGraf] = useState([])
  const [sayfa, setSayfa] = useState(0);
  const [datadate, setDataDate] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const { accessToken, tokenData, logOut, error } = useContext(AuthContext)
  //HisseDetayButonları
  const buttons = [
    { id: 1, label: '1G' },
    { id: 2, label: '1H' },
    { id: 3, label: '1A' },
    { id: 4, label: '3A' },
    { id: 5, label: '1Y' },
    { id: 6, label: '5Y' },
  ];
  //HisseModalGösterme
  const handleShow = (id, sembol) => {
    setBtnClicked(true);
    setPeriod("1");
    setBarCount(34);
    setBtnSembol(sembol);
    setShow((prevState) => ({ ...prevState, [id]: true }));
  };
  //HisseModalKapatma
  const handleClose = (id, sembol) => {
    setPeriod("1");
    setBarCount(34);
    setBtnSembol(sembol);
    setActiveButton(1)
    setShow((prevState) => ({ ...prevState, [id]: false }));
  };
  //PerformanstablosuGosterme
  const handleShowPerformance = () => {
    setShowPerformance(true);
  };
  //PerformanstablosuKapatma
  const handleClosePerformance = () => {
    setShowPerformance(false);
  };
  //HisseBilgileriniÇekme
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://aosmp.halkyatirim.com.tr/api/api/values/GetModelDisiPortfoyPerformansTablosu/', {
          
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setData(result);
      } catch (error2) {
        setError(error2.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  //HisseListesindeki grafik verilerini çekme
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const fetchPromises = data.map(async (item) => {
          const response = await fetch(`https://apigw-ws3.idealdata.com.tr/DFN?Username=TRADESOFT&Password=atiONGeNdEct&CMD=CHART1&Sembol=${item.sembol}&Periyot=15&BarCount=34`, {
            
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const resultText = await response.text();
          const modifiedResultText = resultText.replace(/"Date":"(\d{4})\.(\d{2})\.(\d{2}) (\d{2}):(\d{2}):(\d{2})"/g, '"Date":"$1-$2-$3 $4:$5:$6"');
          const resultJson = {
            "Sembol": item.sembol,
            "islemtipi": item.islemtipi,
            "str_hedeffiyat": item.str_hedeffiyat,
            "str_sonfiyat": item.str_sonfiyat,
            "str_giristarihi": item.str_giristarihi,
            "str_guncellemetarihi": item.str_guncellemetarihi,
            "str_hedefuzaklik": item.str_hedefuzaklik,
            "data": JSON.parse("[" + modifiedResultText + "]")
          };
          return resultJson;
        });
        const fetchedData = await Promise.all(fetchPromises);
        setSembol(fetchedData);
      } catch (error2) {
        console.error('Error fetching data:', error2);
        setError(error2.message);
      } finally {
        setLoading(false);
      }
    };

    if (data.length > 0) {
      fetchData();
    }
  }, [data]);
  //HisseListesindeki grafik
  const getChartOptions = (hisseItem) => {
    const categories = hisseItem.data.map(dataPoint => dataPoint.Date); // Tarihleri string olarak toplayın
    const seriesData = hisseItem.data.map(dataPoint => ({
      x: dataPoint.Date, // Tarih string olarak kalacak
      y: parseFloat(dataPoint.Close)
    }));
    return {
      series: [{
        name: hisseItem.Sembol,
        data: seriesData
      }],
      options: {
        chart: {
          type: 'line',
          zoom: {
            type: 'x',
            enabled: false,
            autoScaleYaxis: false
          },
          toolbar: {
            show: false
          }
        },
        stroke: {
          curve: 'smooth',
          width: 1,
          colors: ['#00FF00']
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
        },
        xaxis: {
          categories: categories, // String tarihleri ekleyin
          axisBorder: {
            show: true,
            color: '#00FF00'
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: false // Etiketleri göstermek için show'u true yapın
          }
        },
        yaxis: {
          labels: {
            show: false,
          },
          axisBorder: {
            show: true,
            color: '#00FF00'
          },
          axisTicks: {
            show: false
          }
        },
        grid: {
          show: false
        },
        tooltip: {
          enabled: false
        }
      }
    };
  };
  //HisseModal'ındaki grafik
  const getHisseChartOptions = (hisseItem) => {
    const categories = hisseItem.data.map(dataPoint => dataPoint.Date); // Tarihleri string olarak toplayın
    const seriesData = hisseItem.data.map(dataPoint => ({
      x: dataPoint.Date, // Tarih string olarak kalacak
      y: parseFloat(dataPoint.Close)
    }));
    const tarih1 = hisseItem.str_giristarihi;
    const parts = tarih1.split('-');
    const tarih2 = parts[2] + '-' + parts[1] + '-' + parts[0];
    const entryDate = tarih2;
    const entryPrice = hisseItem.str_girisfiyati;
    return {
      series: [{
        name: hisseItem.Sembol,
        data: seriesData,
      }],
      options: {
        chart: {
          width: '100%',
          type: 'area',
          zoom: {
            type: 'x',
            enabled: false,
            autoScaleYaxis: false
          },
          toolbar: {
            show: false
          }
        },
        fill: {
          type: 'gradient',
          colors: ['#2ECE5B', '#2ECE5B', '#2ECE5B'],
          shadeIntensity: 0,
          gradient: {
            shadeIntensity: 0,
            inverseColors: false,
            opacityFrom: 0.8,
            opacityTo: 0,
            stops: [0, 90, 100],
            colorStops: []
          },
        },
        stroke: {
          curve: 'smooth',
          width: 1,
          colors: ['#00FF00']
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
        },
        xaxis: {
          type: "datetime",
          categories: categories, // String tarihleri ekleyin
          axisBorder: {
            show: false,
            color: '#00FF00'
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: false // Etiketleri göstermek için show'u true yapın
          }
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value.toFixed(2); // Virgülden sonra iki rakam göstermek için
            },
            show: true,
            style: {
              colors: ['#AAAAAA'],
              fontSize: '12px',
            },
          },
          axisBorder: {
            show: true,
            color: '#022F51'
          },
          axisTicks: {
            show: false
          }
        },
        grid: {
          show: true,
          borderColor: '#022F51',
        },
        tooltip: {
          enabled: false,
        },
        annotations: {
          points: [
            {
              x: new Date(entryDate).getTime(),
              y: entryPrice,
              marker: {
                size: 5,
                fillColor: "red",
                radius: 2
              },
              label: {
                borderColor: "#FF4560",
                offsetY: 0,
                style: {
                  color: "#fff",
                  background: "#FF4560"
                }
              }
            }
          ]
        }
      }
    };
  };
  //HisseModalındaki grafik verilerini çekme
  useEffect(() => {
    if (btnClicked) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const fetchPromises = data.map(async (item) => {
            const response = await fetch(`https://apigw-ws3.idealdata.com.tr/DFN?Username=TRADESOFT&Password=atiONGeNdEct&CMD=CHART1&Sembol=${btnSembol}&Periyot=${period}&BarCount=${barCount}`, {
              
            });
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const resultText = await response.text();
            const modifiedResultText = resultText.replace(/"Date":"(\d{4})\.(\d{2})\.(\d{2}) (\d{2}):(\d{2}):(\d{2})"/g, '"Date":"$1-$2-$3 $4:$5:$6"');
            const resultJson = {
              "Sembol": item.sembol,
              "islemtipi": item.islemtipi,
              "str_hedeffiyat": item.str_hedeffiyat,
              "str_sonfiyat": item.str_sonfiyat,
              "str_giristarihi": item.str_giristarihi,
              "str_hedefuzaklik": item.str_hedefuzaklik,
              "str_guncellemetarihi": item.str_guncellemetarihi,
              "data": JSON.parse("[" + modifiedResultText + "]")
            };
            return resultJson;
          });
          const fetchedData = await Promise.all(fetchPromises);
          setSembolDetail(fetchedData);
        } catch (error2) {
          console.error('Error fetching data:', error2);
          setError(error2.message);
        } finally {
          setLoading(false);
        }
      };
      if (data.length > 0) {
        fetchData();
      }
    }
  }, [period, barCount, btnSembol]);
  //HisseModaldaki butonların veri çekme periyodlarını belirleme
  const goStatics = (id, sembol) => {
    setActiveButton(id);
    if (id == 1) {
      setPeriod("1");
      setBarCount(34);
      setBtnClicked(true);
      setBtnSembol(sembol);
    }
    else if (id == 2) {
      setPeriod("G");
      setBarCount(7);
      setBtnClicked(true);
      setBtnSembol(sembol);
    }
    else if (id == 3) {
      setPeriod("G");
      setBarCount("30");
      setBtnClicked(true);
      setBtnSembol(sembol);
    }
    else if (id == 4) {
      setPeriod("G");
      setBarCount("90");
      setBtnClicked(true);
      setBtnSembol(sembol);
    }
    else if (id == 5) {
      setPeriod("G");
      setBarCount("365");
      setBtnClicked(true);
      setBtnSembol(sembol);
    }
    else if (id == 6) {
      setPeriod("G");
      setBarCount("1825");
      setBtnClicked(true);
      setBtnSembol(sembol);
    }
  };
  //PerformansTablosuStart
  function artir() {
    if (sayfa >= 36) {
      setSayfa(36);
    } else {
      setSayfa(sayfa + 1);
    }
  };
  function azalt() {
    if (sayfa <= 1) {
      setSayfa(1);
    } else {
      setSayfa(sayfa - 1);
    }
  };
  useEffect(() => {
    fetch("https://aosmp.halkyatirim.com.tr/api/api/Values/GetMP_PerformansGrafik2/")
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setFirstResult(result);
          setSayfa(1);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, []);

  useEffect(() => {
    if (firstresult.length > 0) {
      const myBest = firstresult.slice(-(10 * sayfa), firstresult.length - 10 * (sayfa - 1));
      setItemsGrafik(myBest);
    }
  }, [firstresult, sayfa]);

  useEffect(() => {
    if (graf.length > 0) {
      const datadate = [];
      const data1 = [];
      const data2 = [];

      graf.forEach(item => {
        datadate.push(item.degerlemetarih);
        data1.push(item.rg);
        data2.push(item.kpg);
      });

      setDataDate(datadate);
      setData1(data1);
      setData2(data2);
    }
  }, [graf]);

  useEffect(() => {
    // Eğer 'graf' verisi 'firstresult' üzerinden türetiliyorsa, burada 'firstresult' değiştiğinde 'graf'ı güncelleyin.
    if (firstresult.length > 0) {
      setGraf(firstresult); // Örnek olarak 'graf' verisini 'firstresult' ile güncelliyoruz. Burada gerçek işleme göre değişiklik yapmanız gerekebilir.
    }
  }, [firstresult]);
  //PerformansTablosuEnd
  //RefreshIconStart
  const handleRefresh = () => {
    window.location.reload(); // This will reload the page
  };
  //RefreshIconEnd
  const chartContainerRef = useRef(null);
  const toggleSeries = (seriesName) => {
    // ApexChart referansını kullanarak series'in görünürlüğünü değiştirin
    if (chartContainerRef.current) {
      console.log(seriesName);
      const chart = chartContainerRef.current.chart;
      const series = chart.w.globals.seriesNames.indexOf(seriesName);
      if (series !== -1) {
        chart.toggleSeries(seriesName);
      }
    }
  };
  useEffect(() => {}, [chartContainerRef]);
  const decoded = jwtDecode(accessToken);
  if (!accessToken) {
    return <div className='vh-100 d-flex align-items-center justify-content-center'>
              <div className='text-center text-white'>Lütfen Giriş Yapınız</div>
          </div>;
  }
  else {
    return (
      <div className="App">
        <header className="App-header">
          <span>Giren Hisseler</span>
          <Hamburger size={20} onToggle={toggled => {
            if (toggled) {
              setIsOpen(true);
            } else {
              setIsOpen(false);
            }
          }} />
        </header>
        <div className="App-title">
          <span>Araştırma Kapsamındakiler</span>
          <img src={RefreshIcon} onClick={handleRefresh} style={{ cursor: 'pointer', width: '32px' }} />
        </div>
        <div className='Cards'>
          {Hisse.map((item, key) => (
            <div className='CardBox' key={item.Sembol}>
              <div onClick={() => handleShow(key, item.Sembol)} style={{ cursor: 'pointer' }}>
                <div className='CardHeader'>
                  <div className='CardHeaderLogo'>
                    <img src={aselsanImage} alt={item.Sembol} />
                    <div className='CardHeaderInfo'>
                      <span className='CompanySembol'>{item.Sembol}</span>
                      <span className='CompanyName'>Aselsan Elektronik Sanayi ve Ticaret AS</span>
                    </div>
                  </div>
                  <div className='CardHeaderGrapgh'>
                    <ReactApexChart
                      options={getChartOptions(item).options}
                      series={getChartOptions(item).series}
                      type="line"
                      height={80}
                      width={90}
                    />
                  </div>
                </div>
                <div className='CardInfos'>
                  <div className='CardInfosLeft'>
                    <span>İşlem Tipi : <strong>{item.islemtipi}</strong></span>
                    <span>Hedef Fiyatı TL : <strong>{item.str_hedeffiyat}</strong></span>
                    <span>Son Fiyat TL : <strong>{item.str_sonfiyat}</strong></span>
                  </div>
                  <div className='CardInfosRight'>
                    <span>Giriş Tarihi : <strong>{item.str_giristarihi}</strong></span>
                    <span>Hedefe Uzaklık : <strong>{item.str_hedefuzaklik}</strong></span>
                    <span>Güncelleme Tarihi : <strong>{item.str_guncellemetarihi}</strong></span>
                  </div>
                </div>
                <div className='CardButtons'>
                  <a src={`app://EqAddOrder/${item.Sembol}/BUY/${decoded.sub_accounts}`} className='btn btn-green'>
                    Al
                  </a>
                  <a src={`app://EqAddOrder/${item.Sembol}/SELL/${decoded.sub_accounts}`} className='btn btn-red'>
                    Sat
                  </a>
                </div>
              </div>
            </div>
          ))}
          <div className='modals'>
            {HisseDetail.map((item, key) => (
              <Modal
                show={show[key]} onHide={() => handleClose(key, item.Sembol)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                centered
                backdrop={true}
              >
                <Modal.Header>
                  <Modal.Title id="example-custom-modal-styling-title" >
                    <span className='CompanySembol'>{item.Sembol}</span>
                    <span className='CompanyName'>Aselsan Elektronik Sanayi ve Ticaret AS</span>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <ReactApexChart
                    options={getHisseChartOptions(item).options}
                    series={getHisseChartOptions(item).series}
                    type="area"
                  />
                  <div className='grapgButtons'>
                    <button className={activeButton === 1 ? 'active' : ''} onClick={() => goStatics(1, item.Sembol)}>1G</button>
                    <button className={activeButton === 2 ? 'active' : ''} onClick={() => goStatics(2, item.Sembol)}>1H</button>
                    <button className={activeButton === 3 ? 'active' : ''} onClick={() => goStatics(3, item.Sembol)}>1A</button>
                    <button className={activeButton === 4 ? 'active' : ''} onClick={() => goStatics(4, item.Sembol)}>3A</button>
                    <button className={activeButton === 5 ? 'active' : ''} onClick={() => goStatics(5, item.Sembol)}>1Y</button>
                    <button className={activeButton === 6 ? 'active' : ''} onClick={() => goStatics(6, item.Sembol)}>5Y</button>
                  </div>
                  <div className='PdfButton'>
                    <a href=''>
                      <img src={pdfIcon} alt={item.Sembol} />
                      Bilanço Değerlendirmesi
                    </a>
                  </div>
                  <div className='CardInfos'>
                    <div className='CardInfosLeft'>
                      <span>Giriş Fiyatı TL : <strong>{item.str_girisfiyati}</strong></span>
                      <span>Hedef Fiyatı TL : <strong>{item.str_hedeffiyat}</strong></span>
                      <span>Son Fiyat TL : <strong>{item.str_sonfiyat}</strong></span>
                    </div>
                    <div className='CardInfosRight'>
                      <span>Giriş Tarihi : <strong>{item.str_giristarihi}</strong></span>
                      <span>Hedefe Uzaklık TL : <strong>{item.str_hedefuzaklik}</strong></span>
                      {
                        item.str_getiri > 0 ?
                          <>
                            <span className='green_text'>Getiri % : <strong>{item.str_getiri}</strong></span>
                          </>
                          : (
                            <span className='red_text'>Getiri % : <strong>{item.str_getiri}</strong></span>
                          )}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <a src={`app://EqAddOrder/${item.Sembol}/BUY/${decoded.sub_accounts}`} className='btn btn-green'>
                    Al
                  </a>
                  <a src={`app://EqAddOrder/${item.Sembol}/SELL/${decoded.sub_accounts}`} className='btn btn-red'>
                    Sat
                  </a>
                </Modal.Footer>
              </Modal>
            ))}
          </div>
        </div>
        <Modal
          show={showPerformance} onHide={() => handleClosePerformance()}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          centered
          backdrop={true}
        >
          <Modal.Header>
            <Modal.Title id="example-custom-modal-styling-title" >
              <span className='CompanySembol'>Performans Tablosu</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id="chart" ref={chartContainerRef}>
              <ApexChart
                ref={chartContainerRef}
                categories={datadate}
                data={data1}
                data2={data2}
              />
            </div>
            <div className="nextprev">
              <button
                appearance="primary"
                size="xsmall"
                className="p-1 mx-1 nextprevbtn"
                onClick={artir}
              >
                <img src={performance_Left} />
              </button>
              <button
                appearance="primary"
                size="xsmall"
                className="p-1 mx-1 nextprevbtn"
                onClick={azalt}
              >
                <img src={performance_Right} />
              </button>
            </div>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>
        <div ref={menuRef} className={`menu ${isOpen ? 'open' : ''}`}>
          <hr />
          <ul>
            <li><a href='/' >Hisseler Listesi <img src={rightIcon} /></a></li>
            <li><a href='javascript' onClick={() => handleShowPerformance()}>Performans Tablosu <img src={rightIconPas} /></a></li>
            <li><a href='/GirenHisseler'>Model Portföye Yeni Alınan ve Ağırlığı Artırılan Hisseler <img src={rightIconPas} /></a></li>
            <li><a href='/CikanHisseler'>Model Portföyden Çıkan ve Ağırlığı Azaltılan Hisseler <img src={rightIconPas} /></a></li>
            <li><a className='active' href='/ArastirmaKapsamindakiler'>Araştırma Kapsamındaki Hisseler <img src={rightIconPas} /></a></li>
          </ul>
        </div>
      </div>
    );
  }
  
}
export default ArastirmaKapsamindakiler;