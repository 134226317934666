import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import HisseListesi from './HisseListesi';
import EnteredTable from '../src/pages/EnteredTable/Index';
import ExitTable from '../src/pages/ExitTable/Index';
import SearchedTable from '../src/pages/SearchedTable/Index';
import GirenHisseler from './GirenHisseler';
import CikanHisseler from './CikanHisseler';
import ArastirmaKapsamindakiler from './ArastirmaKapsamindakiler';

import Paths from "./enums/routes";

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<HisseListesi />} />
          <Route path={Paths.EnteredTable} element={<EnteredTable />} />
          <Route path={Paths.ExitTable} element={<ExitTable />} />
          <Route path={Paths.SearchedTable} element={<SearchedTable />} />
          {/* <Route path="/GirenHisseler" element={<GirenHisseler />} /> */}
          {/* <Route path="/CikanHisseler" element={<CikanHisseler />} /> */}
          {/* <Route path="/ArastirmaKapsamindakiler" element={<ArastirmaKapsamindakiler />} /> */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;